import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import qs from 'query-string';
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {TYPE_PREFERENCES, URL} from "../../constants";
import { alert } from './AlertSlice'
import {
    initialProfileExport,
    initialProfileLenta,
    login,
    logout,
    loginMultisession,
    getUserInfo,
    registration
} from './AuthSlice'
import {AUTH_STATUS, IAuth, IQueriesFilter, LANG} from "../../types/IUser";

export enum LOGIN_FIELD {
    LOGIN = 'login',
    PWD = 'pwd'
}

interface IModalProps {
    sendMail: {
        isLoading: boolean,
        isOpen: boolean,
        ids: number[]
    },
    newsletter: {
        isOpen: boolean
    },
    telegram: {
        isOpen: boolean,
        code: string | null
        isLoading: boolean,
    },
    login: {
        isOpen: boolean,
        isLoading: boolean
        [LOGIN_FIELD.LOGIN]: string,
        [LOGIN_FIELD.PWD]: string
    },
    multiSession: {
        isOpen: boolean,
        isLoading: boolean
    },
    passwordRecovery: {
        isOpen: boolean,
        isLoading: boolean
    },
    loginRecovery: {
        isOpen: boolean,
        isLoading: boolean
    }
}


const initialState: IModalProps = {
    sendMail: {
        isLoading: false,
        isOpen: false,
        ids: []
    },
    newsletter: {
        isOpen: false
    },
    telegram: {
        isOpen: false,
        code: null,
        isLoading: false
    },
    login: {
        isOpen: false,
        isLoading: false,
        login: '',
        pwd: ''
    },
    multiSession: {
        isOpen: false,
        isLoading: false
    },
    passwordRecovery: {
        isOpen: false,
        isLoading: false
    },
    loginRecovery: {
        isOpen: false,
        isLoading: false
    }
};


export const sendEmail = createAsyncThunk(
    'modalSlice/export',
    async (params: { format: string, message: string, email: string[], zipFiles: number, lang: LANG, success: { title: string, description: string, button: string}}, { rejectWithValue, dispatch, getState }) => {
        try {
            const state: any = getState();
            const { ids } = state.modal.sendMail;
            const { format, zipFiles, message, email, lang } = params;
            const { data } = await axios.post(URL.EXPORT, qs.stringify({
                article_id: ids,
                email: email,
                message: message,
                zipFiles: zipFiles,
                format: format,
                lang: lang
            }, {arrayFormat: 'bracket'}), {withCredentials: true});

            if(data.status === 'Email sent'){
                setTimeout(() => {
                    dispatch(alert(params.success))
                },100);
            }
            return data;
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const getCodeTelegram = createAsyncThunk(
    'modalSlice/getCodeTelegram',
    async (_, { rejectWithValue, dispatch, getState }) => {
        try {
            const { data } = await axios.post(URL.GET_CODE_TELEGRAM);
            return data?.code || ''
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const modalSlice = createSlice({
    name: 'modalSlice',
    initialState,
    reducers: {
        sendMailOpen(state: IModalProps, action: PayloadAction<number[]>){
            state.sendMail.isOpen = true;
            state.sendMail.ids  = action.payload
        },
        sendMailClose(state: IModalProps){
            state.sendMail.isOpen = false;
            state.sendMail.ids = []
        },
        newsletterOpen(state: IModalProps){
            state.newsletter.isOpen = true
        },
        newsletterClose(state: IModalProps){
            state.newsletter.isOpen = false
        },
        telegramModalOpen(state: IModalProps){
            state.telegram.isOpen = true
        },
        telegramModalClose(state: IModalProps){
            state.telegram.isOpen = false
        },
        setLoginOpen(state: IModalProps, action: PayloadAction<boolean>){
            state.login.isOpen = action.payload
        },
        setLoginField(state: IModalProps, action: PayloadAction<{name: LOGIN_FIELD, value: string}>){
            const { name, value } = action.payload;

            state.login[name] = value
        },
        setMultiSessionOpen(state: IModalProps, action: PayloadAction<boolean>){
            state.multiSession.isOpen = action.payload;
        },
        setPasswordRecoveryOpen(state: IModalProps, action: PayloadAction<boolean>){
            state.passwordRecovery.isOpen = action.payload;
        },
        setLoginRecoveryOpen(state: IModalProps, action: PayloadAction<boolean>){
            state.loginRecovery.isOpen = action.payload;
        }
    },
    extraReducers: {
        [sendEmail.pending.type]: (state: IModalProps) => {
            state.sendMail.isLoading = true;
        },
        [sendEmail.fulfilled.type]: (state: IModalProps) => {
            state.sendMail.isLoading = false;
            state.sendMail.isOpen = false;
        },
        [getCodeTelegram.pending.type]: (state: IModalProps) => {
            state.telegram.isLoading = true;
        },
        [getCodeTelegram.fulfilled.type]: (state: IModalProps, action: PayloadAction<string>) => {
            const code = action.payload;

            state.telegram.isLoading = false;
            state.telegram.code = code;
        },
        [login.fulfilled.type]: (state: IModalProps, action: PayloadAction<any>) => {
            const data = action.payload;

            state.login.isLoading = false;

            if(data.auth === AUTH_STATUS.LOGIN) {
                state.login.isOpen = false;
            }
        },
        [login.pending.type]: (state: IModalProps) => {
            state.login.isLoading = true;
        },
        [login.rejected.type]: (state: IModalProps, action: PayloadAction<any>) => {
            state.login.isLoading = false;

            if(action.payload?.response?.status === 402){
                state.multiSession.isOpen = true;
            }
        },
        [registration.pending.type]: (state: IModalProps) => {
            state.login.isLoading = true;
        },
        [registration.fulfilled.type]: (state: IModalProps, action: PayloadAction<any>) => {
            const data = action.payload;

            state.login.isLoading = false;
            if(data?.Status === "Success"){
                state.login.isOpen = false;
            }
        },
        [getUserInfo.fulfilled.type]: (state: IModalProps, action: PayloadAction<any>) => {
            state.login.isOpen = false;
            state.login.isLoading = false;
        },
        [logout.fulfilled.type]: (state: IModalProps) => {
            state.login.isOpen = false;
        },
        [loginMultisession.fulfilled.type]: (state: IModalProps) => {
            state.multiSession.isLoading = false;
        },
        [loginMultisession.pending.type]: (state: IModalProps) => {
            state.multiSession.isLoading = true;
        }
    }
});

export const { sendMailOpen, sendMailClose, newsletterOpen, newsletterClose, telegramModalOpen, telegramModalClose, setLoginOpen, setLoginField, setMultiSessionOpen, setPasswordRecoveryOpen, setLoginRecoveryOpen} = modalSlice.actions;
export default modalSlice.reducer;
import React, { FC, useState, MouseEvent, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Modal from '../index';
import Newsletter from '../Newsletter';
import InputTags from '../../Input/InputTags';
import Icon from '../../Icon';
import ListMail from './ListMail';
import Input from '../../Input/Input/Input';
import TextArea from '../../Input/TextArea/TextArea';
import RadioGroup from '../../Input/Radio/RadioGroup';
import Radio from '../../Input/Radio/Radio';
import Checkbox  from '../../Input/Checkbox/Checkbox';
import Button, {BUTTON_GROUP_ALIGN, BUTTON_VARIANT, ButtonGroup} from '../../Button';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {sendEmail, sendMailClose, newsletterOpen} from "../../../store/reducers/ModalSlice";
import {ICON_VARIANT} from "../../../assets/svg";
import { INPUT_VARINAT, OnCheckboxChange, OnInputChange} from "../../../types/IInput";
import {EXPORT_FORMAT} from "../../../store/reducers/ExportSlice";
import {LOG_FORMAT, logHistory} from "../../../store/reducers/HistorySlice";
import {useTranslate} from "../../../hooks/useTranslate";
import css from './SendMail.module.scss';
import cssModal from '../Modal.module.scss';
import {IMailingList} from "../../../types/IUser";
import {arrayCompareCheckbox, arrayCompareSomeCheckbox, getUnique} from "../../../utils/utils";

const initialState = {
    email: [],
    message: '',
    format: EXPORT_FORMAT.PDF,
    zipFiles: 0
};

const SendMail: FC = () => {
    const { isLoading, isOpen, ids } = useAppSelector(state => state.modal.sendMail);
    const { mailing_lists, lang } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const [form, setForm] = useState<{email: string[], message: string, format: EXPORT_FORMAT, zipFiles: number}>(initialState);
    const [list, setList] = useState<{prev: IMailingList[], current: IMailingList[]}>({prev: [], current: []});
    const [show, setShow] = useState<boolean>(false);
    const translate = useTranslate();

    const onMail = (mail: string[]) => {
        setForm(prev => ({...prev, ...{email: getUnique(mail)}}));
        setList(state => ({...state, ...{current: state.current.filter(item => arrayCompareSomeCheckbox(mail,item.value.list))}}))
    };

    const onChange = ({name, value}: OnInputChange | {name: string, value: number}) => {
        setForm(prev => ({...prev, ...{[name]: value}}));
    };

    const onFormat = ({value}: any) => {
        setForm(prev => ({...prev, ...{format: value}}));
    };

    const onClean = () => {
        setForm(initialState);
        setList({prev: [], current: []});
    };

    const onAdd = () => {
        const prevEmail = list.prev.map(l => l.value.list).flat();
        const currentEmail = list.current.map(l => l.value.list).flat();
        const mail = form.email.filter(state => !prevEmail.includes(state)).concat(currentEmail);
        onMail(mail);
        setList({
            prev: list.current,
            current: list.current
        });
        setShow(false);
    };

    const callback = (data: IMailingList) => {
        const prev = list.prev.map(item => item.id === data.id ? data : item);
        const current = list.current.map(item => item.id === data.id ? data : item);
        setList({prev, current});
    };

    const send = () => {
        if(ids.length){
            dispatch(sendEmail({
                ...form,
                lang: lang,
                success: {
                    title: translate.senMail.success.title,
                    description: translate.senMail.success.description,
                    button: translate.success
                }
            }));
            dispatch(logHistory({
                article_id: ids,
                format: LOG_FORMAT.MAIL
            }))
        }
    };

    useEffect(() => {
        onClean();
    }, [isOpen]);

    return(
        <>
            <Modal
                isOpen={isOpen}
                close={() => dispatch(sendMailClose())}
                title={translate.senMail.title}
            >
                <div className={cssModal.content}>
                    <div className={cssModal.text}>{translate.senMail.description}</div>
                    <div className={css.fieldTitle}>{translate.senMail.address}</div>
                    <div className={css.row}>
                        <InputTags
                            value={form.email}
                            onChange={onMail}
                        />
                        <div className={css.add} onClick={() => setShow(true)}>
                            {translate.senMail.create}
                            <span className={css.addIcon}>
                                <Icon name={ICON_VARIANT.PLUS}/>
                            </span>
                        </div>
                    </div>
                    <TextArea title={translate.senMail.annotate} variant={INPUT_VARINAT.GREY} value={form.message} name={'message'} onChange={onChange} />
                    <div className={cssModal.subtitle}>{translate.senMail.format}</div>
                    <RadioGroup name={'format'} value={form.format} onChange={onFormat}>
                        {Object.values(EXPORT_FORMAT).map(format =>
                            <Radio key={format} variant={INPUT_VARINAT.GREY} value={format} title={format}/>
                        )}
                    </RadioGroup>
                    <Checkbox
                        variant={INPUT_VARINAT.BLUE}
                        name={"zipFiles"}
                        value={Boolean(form.zipFiles)}
                        title={translate.senMail.zip}
                        onChange={({name, value}: OnCheckboxChange) => onChange({name, value: Number(value)})}/>
                    <ButtonGroup align={BUTTON_GROUP_ALIGN.RIGHT}>
                        <Button disabled={!Boolean(ids.length && form.email.length)} isLoading={isLoading} onClick={send}>{translate.senMail.send}</Button>
                    </ButtonGroup>
                </div>
                {show
                    ? <div className={css.create}>
                        <div className={css.background} onClick={() => setShow(false)}/>
                        <div className={css.wrapper}>
                            <div className={css.inner}>
                                <div className={css.list}>
                                    {mailing_lists.map(item =>
                                        <ListMail
	                                        key={item.id}
                                            data={item}
                                            active={list.current.some(l => l.id === item.id)}
	                                        onChange={({name, value}: OnCheckboxChange) => {
                                                const newValue = value ? list.current.concat(item) : list.current.filter(l => l.id !== item.id);
                                                setList(state => ({...state, ...{current: newValue}}));
                                            }}
                                        />
                                    )}
                                </div>
                                <div className={css.devider}/>
                                <div className={css.settings} onClick={() => dispatch(newsletterOpen())}>
                                    {translate.senMail.settings}
                                    <span className={css.settingsIcon}>
                                        <Icon name={ICON_VARIANT.SETTINGS}/>
                                    </span>
                                </div>
                            </div>
                            <div className={css.footer}>
                                <ButtonGroup align={BUTTON_GROUP_ALIGN.RIGHT}>
                                    <Button variant={BUTTON_VARIANT.TEXT} onClick={() => setShow(false)}>{translate.senMail.cancel}</Button>
                                    <Button disabled={!list.prev.length && !list.current.length} onClick={onAdd}>{translate.senMail.add}</Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                    : null
                }
                <Newsletter callback={callback}/>
            </Modal>
        </>
    )
};

export default SendMail;
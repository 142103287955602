import {COLORS_TEXT} from "../constants";

export enum AUTH_STATUS {
    LOGIN = 'Login OK',
    LOGOUT = 'Logout OK',
    NOT_AUTHARIZED = 'not_authorized',
    AUTH_ERROR = 'Login incorrect [2]',
    ANOTHER_SESSION = 'Another session is active.',
    NO_WEB_SUBSCRIPTION = 'No web subsrtiptions.'
}


export enum LANG {
    RU = 'ru',
    EN = 'en',
    CN = 'chi',
    ES = 'esp',
    EG = 'arab',
    FA = 'far'
}


export enum THEME {
    RIA = 'ria',
    SPUNTIK = 'spuntik'
}

export interface ILogin {
    login: string,
    pwd: string
}

interface ITerms {
    [key: number]: {
        fd: string,
        td: string
    }
}

export interface IUser {
    auth_type: boolean
    authorized: boolean
    autologin: boolean | null
    email: string
    group_id: number
    id: string
    login: string
    nickName: string
    provider: string
    subscriptions: number[] | null
    telegram_linked: boolean
    terms: ITerms | null
    debug: boolean
    user_id: number
    user_type: number
}

export interface ISid {
    id: number,
    'id-parent': number,
    is_active: boolean,
    list_is_internal: number
    lang: string,
    sid: string,
    title: string,
    type: string,
    url: string,
}

export interface IGroup {
    name: string,
    type: string,
    user_id: string,
    value: string[]
}

export interface IQueries {
    id: string | null,
    name: string,
    type: string,
    value: IQueriesFilter
}

export interface IQueriesFilter {
    sid_list: string[],
    fields: string[],
    stopwords: string,
    priority: number[],
    case_sensitive: number | null,
    advanced_search: number | null,
    media_type: string[],
    query: string,
    match: string,
    send_to_telegram: boolean
}

export interface IPinnedArticles {
    name: string,
    type: string,
    value: number[]
}

export interface IComet {
    ticket: string,
    timestamp: string,
    url: string
}

export interface IFavorite {
    id: string | null,
    name: string,
    type: string,
    value: number[],
    sortkey?: {
        sec: number,
        usec: number
    }
}

export interface IProfileLenta {
    name: string,
    type: string,
    value: {
        tooltip: boolean,
        scrollFixed: boolean,
        sizeTitle: string,
        sizeBody: string,
        hideSids: boolean,
        lightingQuery: boolean,
        lightingQueryColor: string,
        lightingFavorite: boolean,
        lightingFavoriteColor: string,
    }
}


export enum EXPORT_DISPLAY {
    LINE = 'line',
    STEP = 'step'
}

export enum EXPORT_MEDIA_SIZE {
    SMALL = 'line',
    MEDIUM = 'small',
    LARGE = 'large'
}

export enum EXPORT_MEDIA_TYPE {
    ALL = 'all',
    MAIN = 'main'
}


export interface IProfileExportValue {
    display: EXPORT_DISPLAY, // 1 - поочередно, 2 - раздельно // 'line','step'
    media: boolean,
    mediaSize: EXPORT_MEDIA_SIZE, // 1 - маленький, 2 - средний, 3 - большой // 'small', 'medium', 'large'
    mediaType: EXPORT_MEDIA_TYPE, // 1 - все медиа, 2 - только главное // 'all', 'main'
    showDate: boolean,
    showTime: boolean,
    showTitle: boolean,
    showKeywords: boolean,
    sizeTitle: string,
    sizeBody: string,
    sizeLineHeight: string
}

export interface IProfileExport {
    name: string,
    type: string,
    value: IProfileExportValue
}

export interface IMailingList {
    id: string,
    name: string,
    type: string,
    value: {
        list: string[],
        description: string
    }
}

export interface IAuth {
    auth: AUTH_STATUS | null
    error: AUTH_STATUS | null,
    errorRegistration: string | null,
    logout: boolean,
    user: IUser | null,
    lang: LANG,
    rtl: boolean,
    topline: boolean,
    showBanner: boolean,
    theme: THEME,
    list: ISid[],
    articles: IFavorite[],
    groups: IGroup[],
    queries: IQueries[],
    profile_export: [IProfileExport],
    profile_lenta: IProfileLenta[],
    pinned_articles: IPinnedArticles[],
    mailing_lists: IMailingList[],
    comet: IComet | null
}
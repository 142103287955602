import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import qs from 'query-string';
import axios from "axios";
import { COLORS_FAVORITE, COLORS_TEXT, PREMIUM_USER_TYPE, TYPE_PREFERENCES, URL} from '../../constants';
import {FILTER_TYPE, setFilterParams, setFilterType} from './FilterSlice';
import {GS_VARIANT, setScreen} from './ScreenSlice';
import {
    ILogin,
    IAuth,
    AUTH_STATUS,
    ISid,
    IQueries,
    IPinnedArticles,
    IComet,
    IFavorite,
    IProfileLenta,
    IProfileExport,
    IMailingList,
    EXPORT_DISPLAY,
    EXPORT_MEDIA_SIZE,
    EXPORT_MEDIA_TYPE,
    LANG,
    THEME,
    IGroup
} from "../../types/IUser"
import { socialAuth } from '../../utils/social.auth'
import {analyticsAuth, analyticsAuthSocial} from "../../utils/analytics";
import {filterFeeds, getCookie, objValues, setCookie} from "../../utils/utils";
import {alert} from "./AlertSlice";
import { setLoginOpen } from "./ModalSlice";

axios.defaults.withCredentials = true;

interface IState extends IAuth {
    isLoading: boolean,
    isOpenMobile: boolean,
    isOpenDemo: boolean,
    isOpenSubscription: boolean
}

const getLang = (): LANG => {
    const lang = localStorage.getItem('lang');
    const hostname = window.location.hostname;
    const params = qs.parse(window.location.search);

    if(params && params.lang && objValues(LANG).includes(params.lang as any)){
        localStorage.setItem('lang', params.lang as LANG);
        return params.lang as LANG
    }else if(lang && objValues(LANG).includes(lang as any)){
        return lang as LANG
    } else if(hostname.indexOf('wire.sputniknews.com') > -1){
      return LANG.EN
    } else{
        return LANG.RU;
    }
};

const getRtl = (): boolean => {
    const rtl = localStorage.getItem('rtl');

    if(rtl && rtl === '1'){
        return true;
    } else {
        return false;
    }
};

const getTopline = (): boolean => {
    const cookie = getCookie('topline');

    return false
    // return cookie !== '1'
};

const getTheme = (): THEME => {
    const hostname = window.location.hostname;

    if(hostname.indexOf('wire.sputniknews.com') > -1){
        return THEME.SPUNTIK
    }
    return THEME.RIA
};

export const initialProfileLenta: IProfileLenta = {
    name: 'main',
    type: TYPE_PREFERENCES.PROFILE_LENTA,
    value: {
        tooltip: true,
        scrollFixed: false,
        sizeTitle: '32px',
        sizeBody: '16px',
        hideSids: false,
        lightingQuery: false,
        lightingQueryColor: COLORS_TEXT[0],
        lightingFavorite: false,
        lightingFavoriteColor: COLORS_FAVORITE[0],
    }
};

export const initialProfileExport: IProfileExport = {
    name: 'main',
    type: TYPE_PREFERENCES.PROFILE_EXPORT,
    value: {
        display: EXPORT_DISPLAY.LINE,   // 'line','step'
        media: true,
        mediaSize: EXPORT_MEDIA_SIZE.SMALL,  // 'small', 'medium', 'large'
        mediaType: EXPORT_MEDIA_TYPE.ALL, // 'all', 'main'
        showDate: true,
        showTime: true,
        showTitle: true,
        showKeywords: true,
        sizeTitle: '30px',
        sizeBody: '16px',
        sizeLineHeight: '22px'
    }
};


const initialState: IState = {
    auth: null,
    user: null,
    error: null,
    errorRegistration: null,
    logout: false,
    // lang: LANG.RU,
    // rtl: true,
    lang: getLang(),
    rtl: getRtl(),
    topline: getTopline(),
    showBanner: false,
    theme: getTheme(),
    list: [],
    articles: [],
    groups: [],
    queries: [],
    pinned_articles: [],
    profile_export: [initialProfileExport],
    profile_lenta: [initialProfileLenta],
    mailing_lists: [],
    comet: null,
    isLoading: false,
    isOpenMobile: false,
    isOpenDemo: false,
    isOpenSubscription: false
};


export enum SOCIAL_AUTH {
    VK = 'LOGIN_SOCIAL_VK',
    OK = 'LOGIN_SOCIAL_OK',
    APPLE = 'LOGIN_SOCIAL_APPLE',
    GOOGLE = 'LOGIN_SOCIAL_GOOGLE',
}


export const loginMultisession = createAsyncThunk(
    'auth/loginMultisession',
    async (data: ILogin, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios(URL.LOGIN_MULTISESSION,{
                method: 'post',
                data: qs.stringify({event: 'allowaccess'}),
                withCredentials: true
            });

            if(response.status === 200) {
                dispatch(login(data));
            }

            return response.data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const checkCredentials = createAsyncThunk(
    'auth/checkCredentials',
    async (params: ILogin, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axios(URL.CHECK_CREDENTIALS, {
                method: 'post',
                data: qs.stringify({login: params.login, pwd: params.pwd}),
                withCredentials: true,
            });

            if(data.code === 200){
                dispatch(login(params))
                return {
                    error: null
                }
            }

            return { error: data.auth}
        }
        catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const login = createAsyncThunk(
    'auth/login',
    async (params: ILogin, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axios(URL.LOGIN, {
                method: 'post',
                data: qs.stringify({login: params.login, pwd: params.pwd}),
                withCredentials: true,
            });

            let index = 0;
            let premium = Boolean(data?.user?.group_id !== PREMIUM_USER_TYPE);
            let excludeSids = premium && data?.user?.terms ? filterFeeds(data?.user?.terms) : [];
            let list: ISid[] = data?.list?.filter((item: ISid) => !excludeSids.includes(item.sid)) || [];
            let user = data?.user;
            let groups = ((data?.groups?.length && data.groups) || []).map((item: IGroup) => ({...item, value: item.value.filter(sid => !excludeSids.includes(sid))}));
            let group = groups[0]?.value || [];

            if(group.length && list.length) {
                let arrSids = list.map(e => e.sid);
                index = arrSids.indexOf(group[0]) > 0 ? arrSids.indexOf(group[0]) : 0;

                for(let i = 1; i < group.length; i++){
                    const currentIndex = arrSids.indexOf(group[i]);
                    if(currentIndex >= 0 && currentIndex < index){
                        index = currentIndex;
                    }
                }
            }

            if(data?.user?.group_id !== PREMIUM_USER_TYPE){
                dispatch(setScreen(localStorage.getItem('premiumScreen') || GS_VARIANT.GS3));
            }

            if(group.length && list.length) {
                dispatch(setFilterParams({sid_list: [list[index].sid]}));
            } else if(list.length) {
                dispatch(setFilterParams({sid_list: [list[0].sid]}));
            }

            if(data.auth === AUTH_STATUS.LOGIN){
                analyticsAuth();
                setCookie('BannerRight', '1', 60 * 1000 * 60 * 24 * 730 * 1000, '/');
            }

            return {...data, ...{list: list, groups: groups}}
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const registration = createAsyncThunk(
    'auth/registration',
    async (p: { params: object, success: any}, { rejectWithValue, dispatch }) => {
        try {
            const { params, success } = p;
            const { data } = await axios(URL.REGISTRATION, {
                method: 'post',
                data: qs.stringify(params),
                withCredentials: true,
            });

            if(data?.Status === "Success"){
                dispatch(alert(success));
            }

            return data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const confirmEmail = createAsyncThunk(
    'auth/confirmEmail',
    async (params: {hash: string, success: any, error: any}, { rejectWithValue, dispatch }) => {
        try {
            const { hash, success, error } = params;
            const { data } = await axios(URL.CONFIRM_EMAIL + '?hash=' + hash, {
                method: 'get',
                withCredentials: true,
            });

            if(data?.Status === "Success"){
                dispatch(alert(success));
            } else {
                dispatch(alert(error));
            }
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const passwordRecovery = createAsyncThunk(
    'auth/passwordRecovery',
    async (params: {form: object, success: any}, { rejectWithValue, dispatch }) => {
        try {

            const { form, success } = params;
            const { data } = await axios(URL.PASSWORD_RECOVERY, {
                method: 'post',
                data: qs.stringify(form),
                withCredentials: true,
            });


            if(data?.Status === "Success"){
                dispatch(alert(success));
            } else if(data){
                dispatch(alert({
                    title: data.Status,
                    description:  data.Message,
                    button: ''
                }));
            }
            return data;
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const loginRecovery = createAsyncThunk(
    'auth/passwordRecovery',
    async (params: {form: object, success: any}, { rejectWithValue, dispatch }) => {
        try {
            const { form, success } = params;
            const { data } = await axios(URL.LOGIN_RECOVERY, {
                method: 'post',
                data: qs.stringify(form),
                withCredentials: true,
            });


            if(data?.Status === "Success"){
                dispatch(alert(success));
            } else if(data){
                dispatch(alert({
                    title: data.Status,
                    description:  data.Message,
                    button: ''
                }));
            }
            return data;
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const logout = createAsyncThunk(
    'auth/logout',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios(URL.LOGOUT,{
                method: 'post',
                withCredentials: true
            });
            localStorage.setItem('premiumScreen', GS_VARIANT.GS2);
            return response.data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const loginSocial = createAsyncThunk(
    'auth/loginSocial',
    async (type: SOCIAL_AUTH, { rejectWithValue, dispatch }) => {
        try {

            const socialAuthSuccess = async (data: any) => {
                console.log('success', data);
                dispatch(getUserInfo());
                analyticsAuthSocial();
            };

            const socialAuthError = async (data: any) => {
                console.log('error', data);
                dispatch(getUserInfo())
            };

            socialAuth(URL[type], socialAuthSuccess, socialAuthError);

        } catch (e) {
            return rejectWithValue(e)
        }
    }
);


export const getUserInfo = createAsyncThunk(
    'auth/info',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axios.post(URL.GET_INFO_USER, null, {withCredentials: true});
            let index = 0;
            let premium = Boolean(data?.user?.group_id !== PREMIUM_USER_TYPE);
            let excludeSids = premium ? filterFeeds(data?.user?.terms) : [];
            let list: ISid[] = data?.list.filter((item: ISid) => !excludeSids.includes(item.sid)) || [];
            let user = data?.user;
            let groups = ((data?.groups?.length && data.groups) || []).map((item: IGroup) => ({...item, value: item.value.filter(sid => !excludeSids.includes(sid))}));
            let group = groups[0]?.value || [];

            if(group.length && list.length) {
                let arrSids = list.map(e => e.sid);
                index = arrSids.indexOf(group[0]) > 0 ? arrSids.indexOf(group[0]) : 0;

                for(let i = 1; i < group.length; i++){
                    const currentIndex = arrSids.indexOf(group[i]);
                    if(currentIndex >= 0 && currentIndex < index){
                        index = currentIndex;
                    }
                }
            }

            if(data?.auth === AUTH_STATUS.NOT_AUTHARIZED){
                const urlParams: any = qs.parse(window.location.search, {arrayFormat: 'bracket'});
                const lang = urlParams?.lang;
                const values = objValues(LANG);

                if(values.includes(lang)) {
                    dispatch(setLang(lang));
                }
            }

            if(data?.user?.group_id !== PREMIUM_USER_TYPE){
                dispatch(setScreen(localStorage.getItem('premiumScreen') || GS_VARIANT.GS3));
            }

            if(data?.user?.user_type === PREMIUM_USER_TYPE){
                dispatch(setFilterType(FILTER_TYPE.OPINION));
            }else if(group.length && list.length) {
                dispatch(setFilterParams({sid_list: [list[index].sid]}));
            } else if(list.length) {
                dispatch(setFilterParams({sid_list: [list[0].sid]}));
            }
            return {...data, ...{list: list, groups: groups}}
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const getTicket = createAsyncThunk(
    'auth/getTicket',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.get(URL.GET_TICKET, {withCredentials: true});
            return response.data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);


export const setProfileLenta = createAsyncThunk(
    'auth/setProfileLenta',
    async (data: IProfileLenta, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post(URL.SET_PREFESENCES, qs.stringify({
                type: TYPE_PREFERENCES.PROFILE_LENTA,
                name: data.name,
                value: JSON.stringify(data.value)
            }, {arrayFormat: 'bracket'}), {withCredentials: true});

            const result = response.data.setPreferences.result;
            return result
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const setProfileExport = createAsyncThunk(
    'auth/setProfileExport',
    async (data: IProfileExport, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post(URL.SET_PREFESENCES, qs.stringify({
                type: TYPE_PREFERENCES.PROFILE_EXPORT,
                name: data.name,
                value: JSON.stringify(data.value)
            }, {arrayFormat: 'bracket'}), {withCredentials: true});

            const result = response.data.setPreferences.result;
            return result
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const createNewsletter = createAsyncThunk(
    'auth/createNewsletter',
    async (data: Omit<IMailingList, 'type'>, { rejectWithValue }) => {
        try {
            const response = await axios.post(URL.SET_PREFESENCES, qs.stringify({
                type: TYPE_PREFERENCES.MAILING_LIST,
                name: data.name,
                value: JSON.stringify(data.value)
            }, {arrayFormat: 'bracket', skipNull: true}), {withCredentials: true});

            const result = response.data.setPreferences.result;
            return result
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);


export const deleteNewsletter = createAsyncThunk(
    'auth/deleteNewsletter',
    async (params: Omit<IMailingList, 'type'>, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axios.post(URL.DELETE_PREFERENS, qs.stringify({
                type: TYPE_PREFERENCES.MAILING_LIST,
                id: params.id
            }, {arrayFormat: 'bracket', skipNull: true}), {withCredentials: true});

            return {
                id: params.id,
                data: data?.user
            }
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const updateNewsletter = createAsyncThunk(
    'auth/updateNewsletter',
    async (data: Omit<IMailingList, 'type'>, { rejectWithValue }) => {
        try {
            const response = await axios.post(URL.SET_PREFESENCES, qs.stringify({
                type: TYPE_PREFERENCES.MAILING_LIST,
                id: data.id,
                name: data.name,
                value: JSON.stringify(data.value)
            }, {arrayFormat: 'bracket', skipNull: true}), {withCredentials: true});

            const result = response.data.setPreferences.result;
            return result
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        openMobileModal(state: IState) {
            state.isOpenMobile = true
        },
        closeMobileModal(state: IState) {
            state.isOpenMobile = false
        },
        openDemoModal(state: IState) {
            state.isOpenDemo = true
        },
        closeDemoModal(state: IState) {
            state.isOpenDemo = false
        },
        openSubscribeModal(state: IState) {
            state.isOpenSubscription = true
        },
        closeSubscribeModal(state: IState) {
            state.isOpenSubscription = false
        },
        setGroups(state: IState, action){
            state.groups = [action.payload];
        },
        addQueris(state: IState, action: PayloadAction<IQueries>){
            const queries = action.payload;
            const index = state.queries.map(item => item.id)?.indexOf(queries.id);

            if(index !== -1){
                state.queries[index] = queries;
            } else {
                state.queries = state.queries.concat(queries);
            }
        },
        removeQueries(state: IState, action: PayloadAction<string[]>){
            const ids = action.payload;
            state.queries = state.queries.filter(item => !ids.includes(item.id as string)) || [];
        },
        setQueries(state: IState, action: PayloadAction<IQueries[]>){
            state.queries = action.payload
        },
        setPinnedArticles(state: IState, action: PayloadAction<IPinnedArticles[]>){
            state.pinned_articles = action.payload
        },
        addFavorite(state: IState, action: PayloadAction<IFavorite>){
            const articles = action.payload;
            const index = state.articles.map(item => item.id)?.indexOf(articles.id);

            if(index !== -1){
                state.articles[index] = articles;
            } else {
                state.articles = state.articles.concat(articles);
            }
        },
        setFavorite(state: IState, action: PayloadAction<IFavorite>){
            const favorite = action.payload;

            state.articles = state.articles.reduce((accumulator: IFavorite[], currentValue: IFavorite) => {
                if (currentValue.id === favorite.id) {
                    return accumulator.concat(favorite)
                }
                return accumulator.concat(currentValue)
            },[])
        },
        replaceFavorite(state: IState, action: PayloadAction<IFavorite[]>){
            state.articles = action.payload;
        },
        deleteUserFavorite(state: IState, action: PayloadAction<string[]>){
            const ids = action.payload;
            state.articles = state.articles.filter(article => !ids.includes(article.id as string)) || [];
        },
        setLang(state: IState, action: PayloadAction<LANG>){
            const lang = action.payload;

            state.rtl = Boolean(LANG.EG === lang || LANG.FA === lang)
            state.lang = lang;
        },
        changeLang(state: IState, action: PayloadAction<LANG>){
            const lang = action.payload;

            if(LANG.EG === lang || LANG.FA === lang){
                // state.rtl = true
                localStorage.setItem('rtl', '1');
            } else{
                // state.rtl = false
                localStorage.setItem('rtl', '0');
            }
            localStorage.setItem('lang', lang);
            // state.lang = lang;
            window.location.reload();
        },
        setShowBanner(state: IState, action: PayloadAction<boolean>){
            state.showBanner = action.payload;
        },
        setTopline(state: IState, action: PayloadAction<boolean>){
            state.topline = action.payload;
        }
    },
    extraReducers: {
        [checkCredentials.fulfilled.type]: (state: IState, action: PayloadAction<{error: AUTH_STATUS | null}>) => {
            const { error } = action.payload;

            state.error = error;
        },
        [logout.fulfilled.type]: (state: IState, action: PayloadAction<{auth: AUTH_STATUS}>) => {
            state.auth = action.payload.auth;
            state.error = initialState.error;
            state.user = initialState.user;
            state.groups = initialState.groups;
            state.list = initialState.list;
            state.queries = initialState.queries;
            state.articles = initialState.articles ;
            state.pinned_articles = initialState.pinned_articles;
            state.profile_lenta = initialState.profile_lenta;
            state.profile_export = initialState.profile_export;
            state.mailing_lists = initialState.mailing_lists;
            state.isOpenMobile = initialState.isOpenMobile;
            state.isOpenDemo = initialState.isOpenDemo;
            state.isOpenSubscription = initialState.isOpenSubscription;
            state.logout = true;
        },
        [getUserInfo.fulfilled.type]: (state: IState, action: PayloadAction<IAuth>) => {
            const { auth, user, list, groups, queries, pinned_articles, profile_lenta, profile_export, mailing_lists, articles } = action.payload;

            state.auth = auth || null;
            state.error = auth || null;
            state.user = user || null;
            state.showBanner = false;
            state.groups = groups || [];
            state.list = list || [];
            state.queries = queries || [];
            state.articles = articles || [];
            state.pinned_articles = pinned_articles || [];
            state.profile_lenta = (profile_lenta?.length && profile_lenta) || [initialProfileLenta];
            state.profile_export = (profile_export?.length && profile_export) || [initialProfileExport];
            state.mailing_lists = mailing_lists || [];
        },
        [getUserInfo.rejected.type]: (state: IState,  action: PayloadAction<string>) => {
            state.auth = AUTH_STATUS.NOT_AUTHARIZED; //TODO
        },
        [login.fulfilled.type]: (state: IState, action: PayloadAction<IAuth>) => {
            const { auth, user, list, groups, queries, pinned_articles, profile_lenta, profile_export, mailing_lists, articles } = action.payload;

            state.auth = auth || null;
            state.error = auth || null;
            state.user = user || null;
            state.showBanner = true;
            state.list = list || [];
            state.groups = groups || [];
            state.queries = queries || [];
            state.articles = articles || [];
            state.pinned_articles = pinned_articles || [];
            state.mailing_lists = mailing_lists || [];
            state.profile_lenta = (profile_lenta?.length && profile_lenta) || [initialProfileLenta];
            state.profile_export = (profile_export?.length && profile_export) || [initialProfileExport];
            state.logout = false;
        },
        [registration.fulfilled.type]: (state: IState, action: PayloadAction<any>) => {
            const data = action.payload;

            if(data?.Status !== 'Success'){
               state.errorRegistration = data.Message;
            }
        },
        [login.rejected.type]: (state: IState, action: PayloadAction<any>) => {
            state.auth = action.payload?.response?.data?.auth || null;
            state.error = action.payload?.response?.data?.auth || null;
        },
        [getTicket.fulfilled.type]: (state: IState, action: PayloadAction<IComet>) => {
            state.comet = action.payload || null
        },
        [setProfileLenta.pending.type]: (state: IState) => {
            state.isLoading = true
        },
        [setProfileLenta.fulfilled.type]: (state: IState, action: PayloadAction<IProfileLenta>) => {
            state.profile_lenta = [action.payload];
            state.isLoading = false;
        },
        [setProfileExport.pending.type]: (state: IState) => {
            state.isLoading = true
        },
        [setProfileExport.fulfilled.type]: (state: IState, action: PayloadAction<IProfileExport>) => {
            state.profile_export = [action.payload];
            state.isLoading = false;
        },
        [updateNewsletter.pending.type]: (state: IState, action: PayloadAction<IMailingList>) => {
            state.isLoading = true;
        },
        [updateNewsletter.fulfilled.type]: (state: IState, action: PayloadAction<IMailingList>) => {
            const mailingLists = action.payload;

            state.mailing_lists = state.mailing_lists.map(item => item.id === mailingLists.id ? mailingLists : item);
            state.isLoading = false;
        },
        [createNewsletter.pending.type]: (state: IState, action: PayloadAction<IMailingList>) => {
            state.isLoading = true;
        },
        [createNewsletter.fulfilled.type]: (state: IState, action: PayloadAction<IMailingList>) => {
            state.mailing_lists = state.mailing_lists.concat(action.payload);
            state.isLoading = false;
        },
        [deleteNewsletter.fulfilled.type]: (state: IState, action: PayloadAction<{id: string, data: any}>) => {
            const { id, data } = action.payload;

            if(data.deleted_records > 0) {
                state.mailing_lists = state.mailing_lists.filter(item => item.id !== id);
            }
        }
    }
});

export const {openMobileModal, closeMobileModal, openDemoModal, closeDemoModal, openSubscribeModal, closeSubscribeModal, setGroups, setQueries, addQueris, removeQueries, setPinnedArticles, addFavorite, setFavorite, replaceFavorite,  deleteUserFavorite, setLang, changeLang, setShowBanner, setTopline } = authSlice.actions;
export default authSlice.reducer;